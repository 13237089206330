<cf-popover
  [popoverToggle]="popToggle"
  [popoverTemplate]="popContent"
  placement="auto"
  popoverClass="z-1003 p-0"
  [class.full-width]="fullWidth()"
  [fullWidth]="true"
  triggers="click"
  #popoverComponent
  [class.tw:w-10]="compact()"
  [class.tw:w-full]="!compact()"
>
  <ng-template #popToggle>
    <button cfButton [size]="compact() ? 'icon' : 'default'" variant="yellow" [class.tw:w-full]="true">
      @if (!compact()) {
        <span class="tw:truncate">
          {{ "create" | translate }}
        </span>
      }
      <svg cfIcon="plus" class="tw:!mr-0 tw:!ml-0"></svg>
    </button>
  </ng-template>

  <ng-template #popContent>
    <div class="tw:flex tw:flex-col">
      <div
        routerLink="/meeting/create"
        cfOrgRouterLink
        (click)="popoverComponent.handlePopover(true); emitNavigate.emit()"
        class="tw:flex tw:p-8 tw:pb-0 tw:cursor-pointer tw:hover:bg-gray-10 tw:rounded-t-lg"
      >
        <img
          class="tw:w-10 tw:h-10 tw:mr-4"
          alt="{{ 'create_pop.booking_page.one_to_one_alt' | translate }}"
          src="assets/illustration/one_to_one_sm.svg"
        />
        <div class="tw:border-b tw:border-gray-200 tw:pb-4 tw:flex tw:flex-col">
          <span class="tw:text-[18px] tw:font-medium tw:mb-1">{{ "create_pop.booking_page.title" | translate }}</span>
          <span class="tw:text-sm tw:text-gray-70">
            {{ "create_pop.booking_page.description" | translate }}
          </span>
        </div>
      </div>

      <div
        routerLink="/events/flexible"
        cfOrgRouterLink
        (click)="popoverComponent.handlePopover(true); emitNavigate.emit()"
        class="tw:flex tw:p-8 tw:pt-6 tw:pb-0 tw:cursor-pointer tw:hover:bg-gray-10"
      >
        <img
          class="tw:w-10 tw:h-10 tw:mr-4"
          alt="{{ 'create_pop.calendar_event.group_events_alt' | translate }}"
          src="assets/illustration/group_events.svg"
        />
        <div class="tw:border-b tw:border-gray-200 tw:pb-4 tw:flex tw:flex-col">
          <span class="tw:text-[18px] tw:font-medium tw:mb-1">{{ "create_pop.calendar_event.title" | translate }}</span>
          <span class="tw:text-sm tw:text-gray-70">
            {{ "create_pop.calendar_event.description" | translate }}
          </span>
        </div>
      </div>

      <div
        routerLink="/groups/create"
        cfOrgRouterLink
        (click)="popoverComponent.handlePopover(true); emitNavigate.emit()"
        class="tw:flex tw:p-8 tw:cursor-pointer tw:hover:bg-gray-10 tw:rounded-b-lg"
      >
        <img
          class="tw:w-10 tw:h-10 tw:mr-4"
          alt="{{ 'create_pop.new_group.group_events_alt' | translate }}"
          src="assets/illustration/group_to_one_md.svg"
        />
        <div class="tw:flex tw:flex-col">
          <span class="tw:text-[18px] tw:font-medium tw:mb-1">{{ "create_pop.new_group.title" | translate }}</span>
          <span class="tw:text-sm tw:text-gray-70">
            {{ "create_pop.new_group.description" | translate }}
          </span>
        </div>
      </div>
    </div>
  </ng-template>
</cf-popover>
