<cf-toast-container></cf-toast-container>
<ngx-sonner-toaster richColors position="top-right" [toastOptions]="toastOptions" />
<cf-pull-to-refresh></cf-pull-to-refresh>
<cf-header *ngIf="!hideHeader" (toggleSidenav)="toggleSidenav()"></cf-header>

<div class="wrapper">
  @if (authService.isAuthorized()) {
    <cf-sidenav [isAuthorized]="authService.isAuthorized()" *ngIf="!hideHeader"></cf-sidenav>
  }

  <main [class.cf-root-main-container]="!hideHeader">
    <div [class.container]="!hideHeader">
      <header
        [class.d-none]="hideHeader"
        cf-header-user-flow
        (login)="login()"
        (logout)="logout()"
        (register)="register()"
        [isAuthorized]="authService.isAuthorized()"
      ></header>

      <router-outlet></router-outlet>

      <cf-footer *ngIf="isAuthorized() && !hideHeader"></cf-footer>
    </div>
  </main>
</div>
