@use 'utils/breakpoints' as breakpoints;

cf-header {
  header {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin-bottom: 1px;

    background: var(--base-white);
    box-shadow: var(--sidenav-shadow);

    cf-create-button {
      cf-button {
        .cf-btn-create {
          padding: 8px !important;
        }
      }

      .item {
        display: none;
      }
    }
  }

  .alert {
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;

    margin: 0;
    padding: 16px var(--base-body-x-padding);
    display: flex;
    align-items: baseline;
    justify-content: center;
    text-align: center;

    background-color: var(--base-red-10);

    @include breakpoints.lt-xl {
      padding: 16px 15px;
    }
  }
}
