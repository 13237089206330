import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { LoadingService } from '@cf/core';
import { DebounceClickDirective } from '@cf/shared/forms/form-widgets';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { IUserNotice } from '@cf/temp/services';
import { NgxSvgSpriteFragment } from 'ngxtension/svg-sprite';

@Component({
  selector: 'cf-user-notice',
  template: `
    @if (notice) {
      <div [innerHTML]="notice.message"></div>
      <div class="tw:leading-none">
        <svg
          cfIcon="close"
          class="tw:cursor-pointer tw:text-white tw:ml-6"
          cfDebounceClick
          (debounceClick)="emitDismiss.emit()"
          [class.disabled]="loadingService.loadingState$ | async"
        ></svg>
      </div>
      <!-- <cf-icon
        icon="close"
        color="white"
        class="pointer ms-4"
        cfDebounceClick
        (debounceClick)="emitDismiss.emit()"
        [class.disabled]="loadingService.loadingState$ | async"
      ></cf-icon> -->
    }
  `,
  styleUrl: './user-notice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [NgxSvgSpriteFragment, CfIconDirective, DebounceClickDirective, AsyncPipe],
})
export class UserNoticeComponent {
  @Input({ required: true }) notice!: IUserNotice;

  @Output() emitDismiss = new EventEmitter();

  constructor(public readonly loadingService: LoadingService) {}
}
