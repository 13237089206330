import { inject } from '@angular/core';
import { createEffect, ofType } from '@ngneat/effects';
import { map, switchMap, tap } from 'rxjs';
import { OrganizationsService } from '../services';
import { OrganizationsStore } from '../state';
import {
  loadInvitations,
  loadOrganizations,
  manageInvitation,
  manageInvitationByToken,
  manageInvitationError,
  manageInvitationRedirect,
} from './actions';

export class OrganizationsEffect {
  #organizationsService = inject(OrganizationsService);
  #store = inject(OrganizationsStore);

  loadOrganizations$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(loadOrganizations),
        switchMap(() => this.#organizationsService.fetchOrganizations()),
        tap((response) => {
          this.#store.setOrganizations(response);
        }),
      ),
    {
      dispatch: false,
    },
  );

  loadInvitations$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(loadInvitations),
        switchMap(() => this.#organizationsService.fetchInvitations('PENDING')),
        tap((response) => {
          this.#store.setInvitations(response);
        }),
      ),
    {
      dispatch: false,
    },
  );

  manageInvitationByToken$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(manageInvitationByToken),
        switchMap(({ token }) =>
          this.#organizationsService.fetchInvitationsByToken(token).pipe(
            map((response) => {
              if (response.status === 'PENDING') {
                return [
                  manageInvitation({ invitation: response, token: token ?? null }),
                  manageInvitationRedirect({
                    isNewUser: response.invitee_status === 'NOT_REGISTERED',
                    email: response.invitee_email,
                  }),
                ];
              }
              return manageInvitationError(response);
            }),
          ),
        ),
      ),
    {
      dispatch: true,
    },
  );
}
