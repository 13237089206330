import { animate, state, style, transition, trigger } from '@angular/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { CfDialogService } from '@cf/shared/dialog';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { OrganizationAvatarComponent } from '@cf/shared/ui/logos';
import {
  manageInvitation,
  Organization,
  OrganizationInvitations,
  OrganizationsRolesEnum,
  OrganizationsStore,
} from '@cf/temp/services';
import { Actions } from '@ngneat/effects-ng';
import { TippyDirective } from '@ngneat/helipopper';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { OrgRouterLinkDirective } from '../../directives';
@Component({
  selector: 'cf-organization-picker',
  templateUrl: './organization-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterLink,
    CfIconDirective,
    NgxTolgeeModule,
    OrganizationAvatarComponent,
    OverlayModule,
    MatTooltip,
    OrgRouterLinkDirective,
    TippyDirective,
  ],
  host: {
    class: 'tw:block tw:w-full tw:relative',
  },
  animations: [
    trigger('scaleFade', [
      state('void', style({ opacity: 0, transform: 'scale(0.95) translateY(2rem)' })), // Initial hidden state
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(0.95) translateY(2rem)' }),
        animate('150ms ease-out', style({ opacity: 1, transform: 'scale(1) translateY(0)' })),
      ]),
      transition(':leave', [
        animate('100ms ease-in', style({ opacity: 0, transform: 'scale(0.95) translateY(2rem)' })),
      ]),
    ]),
  ],
})
export class OrganizationPickerComponent {
  #store = inject(OrganizationsStore);
  #actions = inject(Actions);
  #dialogService = inject(CfDialogService);
  #elementRef = inject(ElementRef);
  #router = inject(Router);
  #destroyRef = inject(DestroyRef);

  invitations = this.#store.invitationsEntities;
  isDropdownOpen = signal(false);

  compact = input(false);
  size = input<'sm' | 'lg'>('sm');
  selectedOrganization = this.#store.selectedEntity;
  organizations = computed(() => {
    const entities = this.#store.entities();
    const selected = this.selectedOrganization();
    return entities.map((entity) => ({
      ...entity,
      selected: entity.id === selected?.id,
    }));
  });

  isSelectedOrganizationManager = computed(() => {
    const selected = this.selectedOrganization();
    return (
      selected?.role === OrganizationsRolesEnum.ADMIN ||
      selected?.role === OrganizationsRolesEnum.OWNER
    );
  });

  hasAdminLink = input(true);
  hasChevron = input(true);

  avatarSize = signal<'sm' | 'lg'>('sm');

  actualAvatarSize = computed(() => {
    return this.size() === 'lg' ? 'lg' : this.avatarSize();
  });

  get dropdownWidth(): number {
    return Math.max(this.#elementRef.nativeElement.clientWidth, 200);
  }

  constructor() {
    this.#router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.closeDropdown();
      }
    });
    effect(() => {
      if (this.compact()) {
        this.avatarSize.set('lg');
      } else {
        this.avatarSize.set('sm');
      }
    });
  }

  manageInvitation(event: Event, invitation: OrganizationInvitations): void {
    event.preventDefault();
    this.#actions.dispatch(manageInvitation({ invitation }));
  }

  toggleDropdown(event: Event): void {
    event.preventDefault();
    this.isDropdownOpen.set(!this.isDropdownOpen());
  }

  closeDropdown(): void {
    this.isDropdownOpen.set(false);
  }

  normalizeSize(isDone: boolean) {
    if (this.compact() && isDone) {
      this.avatarSize.set('lg');
      return;
    }
    this.avatarSize.set('sm');
  }

  selectOrganization(event: Event, organization: Organization) {
    event.preventDefault();
    this.#store.selectEntity(organization.id);

    const currentPath = location.pathname;
    if (currentPath.includes('/org/')) {
      const newPath = currentPath.replace(/\/org\/[^/]+\/(.*)/, `/org/${organization.id}/$1`);
      this.#router.navigateByUrl(newPath, { onSameUrlNavigation: 'reload' });
    }

    // setTimeout(() => {
    // location.reload();
    // }, 100);
  }
}
