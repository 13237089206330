import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataProvider, localDataProvider, SelectNewComponent } from '@cf/shared/select';
import { ButtonDirective, ButtonLoadingComponent } from '@cf/shared/ui/button';
import { CfIconDirective, IconComponent } from '@cf/shared/ui/icons';
import { TextComponent } from '@cf/shared/ui/typography';
import {
  CalendarStoreService,
  IAvailabilityCalendar,
  ICalendar,
  ISubCalendar,
  OrganizationsService,
} from '@cf/temp/services';

import { AsyncPipe } from '@angular/common';
import { LoadingService } from '@cf/core';
import {
  CfDialogButtonsDirective,
  CfDialogContentDirective,
  CfDialogDismissDirective,
  CfDialogTitleDirective,
  injectDialogData,
  injectDialogRef,
} from '@cf/shared/dialog';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
  selector: 'cf-calendar-settings',
  standalone: true,
  imports: [
    NgxTolgeeModule,
    TextComponent,
    SelectNewComponent,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatTooltipModule,
    ButtonDirective,
    CfIconDirective,
    CfDialogButtonsDirective,
    CfDialogContentDirective,
    CfDialogDismissDirective,
    AsyncPipe,
    CfDialogTitleDirective,
    ButtonLoadingComponent,
    IconComponent,
  ],
  templateUrl: './calendar-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarSettingsModalComponent implements OnInit {
  loading = inject(LoadingService);
  ref = injectDialogRef<boolean>();
  data = injectDialogData<{ organizationId: number }>();
  calendarStoreService = inject(CalendarStoreService);

  $calendars = signal<ICalendar[]>([]);
  bookingCalendarControl = new FormControl<number | undefined>(undefined);
  calendarsProvider: DataProvider<ICalendar>;
  #organizationsService = inject(OrganizationsService);

  conflictCalendars: IAvailabilityCalendar[] = [];

  constructor() {
    this.calendarsProvider = localDataProvider(this.$calendars, {
      labelKey: 'specified_email',
      valueKey: 'id',
    });
  }

  ngOnInit(): void {
    this.calendarStoreService.calendars$.subscribe((calendars) => {
      this.$calendars.set(calendars);
    });
  }

  isChecked(calendar: ICalendar, subCalendar?: ISubCalendar): boolean {
    const selectedCalendar = this.conflictCalendars.find((c) => c.calendar === calendar.id);
    return subCalendar
      ? (selectedCalendar?.subcalendars.includes(subCalendar.id) ?? false)
      : !!selectedCalendar;
  }

  toggleCalendar(calendar: ICalendar, checked: boolean, subCalendar?: ISubCalendar): void {
    const selectedCalendar = this.conflictCalendars.find((c) => c.calendar === calendar.id);

    if (checked) {
      this.addCalendar(calendar, subCalendar, selectedCalendar);
    } else {
      this.removeCalendar(calendar, subCalendar, selectedCalendar);
    }
  }

  private addCalendar(
    calendar: ICalendar,
    subCalendar?: ISubCalendar,
    selectedCalendar?: IAvailabilityCalendar,
  ): void {
    if (!subCalendar) {
      if (!selectedCalendar) {
        this.conflictCalendars.push({ calendar: calendar.id, subcalendars: [] });
      }
    } else {
      if (selectedCalendar) {
        if (!selectedCalendar.subcalendars.includes(subCalendar.id)) {
          selectedCalendar.subcalendars.push(subCalendar.id);
        }
      } else {
        this.conflictCalendars.push({ calendar: calendar.id, subcalendars: [subCalendar.id] });
      }
    }
  }

  private removeCalendar(
    calendar: ICalendar,
    subCalendar?: ISubCalendar,
    selectedCalendar?: IAvailabilityCalendar,
  ): void {
    if (!subCalendar) {
      if (selectedCalendar) {
        this.conflictCalendars = this.conflictCalendars.filter((c) => c !== selectedCalendar);
      }
    } else {
      if (selectedCalendar) {
        selectedCalendar.subcalendars = selectedCalendar.subcalendars.filter(
          (id) => id !== subCalendar.id,
        );
      }
    }
  }

  save(): void {
    this.#organizationsService
      .updateCalendarSettings(
        {
          booking_calendar: this.bookingCalendarControl.value ?? undefined,
          availability_calendars: this.conflictCalendars,
        },
        this.data.organizationId,
      )
      .subscribe(() => {
        this.ref.close(true);
      });
  }
}
