<cf-lang-item [flag]="currentLocale().flag" [abbr]="currentLocale().abbr" (click)="toggleOpened()" />
@if (opened()) {
  <div class="tw:absolute tw:inset-0 tw:bg-white tw:z-10 tw:py-8 tw:px-6">
    <svg
      cfIcon="close"
      class="tw:text-gray-80 tw:cursor-pointer tw:absolute tw:top-4 tw:right-4"
      (click)="toggleOpened()"
    />
    <div class="tw:font-medium tw:text-xl">{{ "header.select_language" | translate }}</div>
    <div class="tw:my-6 tw:h-0.5 tw:bg-raven-10"></div>
    <div>
      @for (option of LocaleOptions; track option.abbr) {
        <div
          class="tw:flex tw:justify-between tw:items-center tw:py-3 tw:cursor-pointer"
          (click)="selectLanguage(option)"
        >
          <div class="tw:flex">
            <cf-lang-item [flag]="option.flag" [abbr]="option.abbr"></cf-lang-item>
            <span class="tw:ms-4" [class.tw:font-medium]="option.abbr === currentLocale().abbr">
              {{ option.lang }}
            </span>
          </div>

          <svg
            cfIcon="check_mark"
            class="tw:text-green-70"
            [class.tw:opacity-0]="option.abbr !== currentLocale().abbr"
          ></svg>
        </div>
      }
    </div>
  </div>
}
