import { Injectable, inject } from '@angular/core';
import { ResponsiveService } from '@cf/core';
import { connectCalendar, connectCalendarFinished } from '@cf/temp/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { createEffect, ofType } from '@ngneat/effects';
import { merge, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ModalConnectCalendarComponent } from '../modal-connect-calendar/modal-connect-calendar.component';

@Injectable({ providedIn: 'root' })
export class AuthDialogEffect {
  #modalService = inject(NgbModal);
  #responsiveService = inject(ResponsiveService);

  connectCalendar$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(connectCalendar),
        switchMap(({ newUser }) =>
          this.#responsiveService.isTouch$.pipe(
            take(1),
            switchMap((isTouch) => {
              const modal = this.#modalService.open(ModalConnectCalendarComponent, {
                size: isTouch ? 'small' : 'medium',
              });
              modal.componentInstance.isNewUser = newUser;
              return merge(modal.dismissed, modal.closed).pipe(
                take(1),
                map(() => connectCalendarFinished()),
              );
            }),
          ),
        ),
      ),
    {
      dispatch: true,
    },
  );
}
