/* eslint-disable no-untranslated-string/no-untranslated-string */
import type { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslateService } from '@tolgee/ngx';

export const languageInterceptor: HttpInterceptorFn = (req, next) => {
  const translateService = inject(TranslateService);
  const language = translateService.language;
  if (language) {
    req = req.clone({
      headers: req.headers.set('Accept-Language', language),
    });
  }
  return next(req);
};
