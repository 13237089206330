import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';

export interface CookieOptions {
  /**
   * Expiration time in days
   */
  expires?: number | Date;
  /**
   * Cookie path
   */
  path?: string;
  /**
   * Cookie domain
   */
  domain?: string;
  /**
   * Use secure cookie
   */
  secure?: boolean;
  /**
   * SameSite attribute (Strict, Lax, None)
   */
  sameSite?: 'Strict' | 'Lax' | 'None';
  /**
   * HttpOnly flag
   */
  httpOnly?: boolean;
}

// Cookie attribute constants
const COOKIE_ATTRIBUTES = {
  PATH: 'path',
  DOMAIN: 'domain',
  SECURE: 'secure',
  SAMESITE: 'samesite',
  HTTPONLY: 'httponly',
  EXPIRES: 'expires',
};

// Default values
const DEFAULT_PATH = '/';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  #platformId = inject(PLATFORM_ID);
  #documentIsAccessible: boolean;

  constructor() {
    this.#documentIsAccessible = isPlatformBrowser(this.#platformId);
  }

  /**
   * Get a cookie by name
   * @param name Cookie name
   * @returns Cookie value or empty string if cookie doesn't exist
   */
  get(name: string): string {
    if (!this.#documentIsAccessible) {
      return '';
    }

    const cookieList = document.cookie ? document.cookie.split('; ') : [];
    const cookieValue = cookieList.map((cookie) => cookie.split('=')).find(([key]) => key === name);

    return cookieValue ? decodeURIComponent(cookieValue[1]) : '';
  }

  /**
   * Set a cookie with the given name and value
   * @param name Cookie name
   * @param value Cookie value
   * @param options Cookie options
   */
  set(name: string, value: string, options: CookieOptions = {}): void {
    if (!this.#documentIsAccessible) {
      return;
    }

    const cookieOptions = this.#buildCookieString(options);
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${cookieOptions}`;
  }

  /**
   * Set a cookie with wildcard domain (e.g., .example.com)
   * This will make the cookie available across all subdomains
   * @param name Cookie name
   * @param value Cookie value
   * @param options Cookie options (domain will be overridden with wildcard domain)
   */
  setWildcardDomain(name: string, value: string, options: CookieOptions = {}): void {
    if (!this.#documentIsAccessible) {
      return;
    }

    // Get the current hostname and extract the root domain
    // For example, convert "app.example.com" to ".example.com"
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    let wildcardDomain = '';

    if (domainParts.length >= 2) {
      // For hostnames like example.com or www.example.com
      const rootDomain = domainParts.slice(-2).join('.');
      wildcardDomain = `.${rootDomain}`;
    } else {
      // For localhost or IP addresses, default to hostname
      wildcardDomain = hostname;
    }

    // Override the domain option with the wildcard domain
    this.set(name, value, { ...options, domain: wildcardDomain });
  }

  /**
   * Check if a cookie exists
   * @param name Cookie name
   * @returns true if cookie exists, false otherwise
   */
  check(name: string): boolean {
    if (!this.#documentIsAccessible) {
      return false;
    }

    return this.get(name) !== '';
  }

  /**
   * Delete a cookie by name
   * @param name Cookie name
   * @param options Cookie options (path and domain are important for proper deletion)
   */
  delete(name: string, options: CookieOptions = {}): void {
    if (!this.#documentIsAccessible) {
      return;
    }

    // To delete a cookie, set its expiration date to the past
    this.set(name, '', { ...options, expires: new Date(0) });
  }

  /**
   * Delete a cookie that was set with a wildcard domain
   * @param name Cookie name
   * @param options Cookie options (path is important for proper deletion)
   */
  deleteFromWildcardDomain(name: string, options: CookieOptions = {}): void {
    if (!this.#documentIsAccessible) {
      return;
    }

    // Get the current hostname and extract the root domain
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    let wildcardDomain = '';

    if (domainParts.length >= 2) {
      const rootDomain = domainParts.slice(-2).join('.');
      wildcardDomain = `.${rootDomain}`;
    } else {
      wildcardDomain = hostname;
    }

    // Delete with the wildcard domain
    this.delete(name, { ...options, domain: wildcardDomain });
  }

  /**
   * Delete all cookies on the current path
   */
  deleteAll(options: CookieOptions = {}): void {
    if (!this.#documentIsAccessible) {
      return;
    }

    const cookieList = document.cookie ? document.cookie.split('; ') : [];

    cookieList.forEach((cookie) => {
      const cookieName = cookie.split('=')[0];
      this.delete(cookieName, options);
    });
  }

  /**
   * Get all cookies as key-value pairs
   * @returns Object with cookie names as keys and cookie values as values
   */
  getAll(): { [key: string]: string } {
    if (!this.#documentIsAccessible) {
      return {};
    }

    const cookieList = document.cookie ? document.cookie.split('; ') : [];

    return cookieList.reduce((cookieObj, cookie) => {
      const [name, value] = cookie.split('=');
      return {
        ...cookieObj,
        [name]: decodeURIComponent(value),
      };
    }, {});
  }

  /**
   * Build cookie options string
   * @param options Cookie options
   * @returns Cookie options as string
   */
  #buildCookieString(options: CookieOptions): string {
    let cookieString = '';

    if (options.expires) {
      const date =
        options.expires instanceof Date
          ? options.expires
          : new Date(Date.now() + options.expires * 24 * 60 * 60 * 1000);

      cookieString += `; ${COOKIE_ATTRIBUTES.EXPIRES}=${date.toUTCString()}`;
    }

    if (options.path) {
      cookieString += `; ${COOKIE_ATTRIBUTES.PATH}=${options.path}`;
    } else {
      // Default path to root if not specified
      cookieString += `; ${COOKIE_ATTRIBUTES.PATH}=${DEFAULT_PATH}`;
    }

    if (options.domain) {
      cookieString += `; ${COOKIE_ATTRIBUTES.DOMAIN}=${options.domain}`;
    }

    if (options.secure === true) {
      cookieString += `; ${COOKIE_ATTRIBUTES.SECURE}`;
    }

    if (options.sameSite) {
      cookieString += `; ${COOKIE_ATTRIBUTES.SAMESITE}=${options.sameSite}`;
    }

    if (options.httpOnly === true) {
      cookieString += `; ${COOKIE_ATTRIBUTES.HTTPONLY}`;
    }

    return cookieString;
  }
}
