<cf-dropdown placement="bottom-right" [showArrow]="true">
  <div class="tw:flex tw:items-center" cf-dropdown-toggle>
    <svg [flag]="currentLocale().flag"></svg>
    <cf-text class="tw:ms-2" color="black" weight="500">{{ localeDisplay() }}</cf-text>
  </div>

  @for (option of LocaleOptions; track option.abbr) {
    <cf-dropdown-item (click)="selectLanguage(option)">
      <div class="tw:flex tw:justify-between tw:items-center">
        <div class="tw:flex">
          <cf-lang-item [flag]="option.flag" [abbr]="option.abbr"></cf-lang-item>
          <span class="tw:ms-4" [class.tw:font-medium]="option.abbr === currentLocale().abbr">
            {{ option.lang }}
          </span>
        </div>

        @if (option.abbr === currentLocale().abbr) {
          <svg cfIcon="check_mark" class="tw:text-green-70"></svg>
        }
      </div>
    </cf-dropdown-item>
  }
</cf-dropdown>
