/* eslint-disable no-untranslated-string/no-untranslated-string */
import { ELang } from './lang.enum';

export interface Locale {
  lang: string;
  abbr: ELang;
  flag: string;
}

export const LocaleOptions: Locale[] = [
  { lang: 'English', abbr: ELang.English, flag: 'us' },
  { lang: 'Français', abbr: ELang.French, flag: 'fr' },
  { lang: 'Русский', abbr: ELang.Russian, flag: 'ru' },
  { lang: 'Deutsch', abbr: ELang.German, flag: 'de' },
  { lang: 'Español', abbr: ELang.Spanish, flag: 'es' },
  { lang: 'Português', abbr: ELang.Portuguese, flag: 'pt' },
];
