@use 'utils/breakpoints' as breakpoints;

cf-user-notice {
  display: flex;
  align-items: baseline;
  justify-content: center;

  padding: 24px;
  text-align: center;
  color: var(--base-white) !important;
  font-size: 18px;
  font-weight: 400;

  border-radius: 8px;
  background: linear-gradient(90deg, #3e77e3 16.42%, #854aea 50.32%, #9360e6 84.9%);

  @include breakpoints.lt-xl {
    font-size: 14px;
  }

  a {
    font-weight: 600;
  }

  * {
    color: inherit;
  }
  //position: absolute;
  //top: 0;
  //left: 0;
  //width: 100%;
  //height: 40px;
}
