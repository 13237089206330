import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { OrgRouterHelperService } from '@cf/features/organizations/admin';
import { AccountService } from '@cf/temp/services';

export const authorizedGuard: CanActivateFn = () => {
  const accountService = inject(AccountService);
  const orgRouterHelperService = inject(OrgRouterHelperService);

  if (accountService.account.id) {
    void orgRouterHelperService.navigate(['/dashboard']);
    return false;
  }

  return true;
};
