<!-- @tolgee-key admin.new_organization_invitation -->
<!-- @tolgee-key admin.invitation_organization -->
<div cfDialogContent class="tw:max-w-[556px]">
  <button class="tw:absolute tw:top-6 tw:right-6" cfDialogDismiss>
    <svg size="sm" cfIcon="dialog_close" class="tw:text-gray-70" />
  </button>

  <div class="tw:flex tw:flex-col tw:gap-4 tw:mb-6">
    <div cfDialogTitle>{{ "admin.new_organization_invitation" | translate }}</div>

    <div>
      @if (data.invitorName && data.organizationName) {
        <div class="tw:text-gray-70 tw:text-base">
          <strong class="tw:font-medium">{{ data.invitorName }}</strong>
          {{ "admin.invitation" | translate }}
          <strong class="tw:font-medium">{{ data.organizationName }}</strong>
          {{ "admin.invitation_organization" | translate }}
        </div>
      }
    </div>
  </div>

  <div cfDialogButtons>
    <button
      cfButton
      variant="warning"
      disableOnRequest
      isLoadingBtn
      (click)="declineInvitation()"
      [loading]="(loading.loadingState$ | async) ?? false"
    >
      <!-- @tolgee-key loading -->
      <cf-button-loading [loadingText]="'loading' | translate">
        {{ "admin.decline_invitation" | translate }}
      </cf-button-loading>
    </button>
    <button
      cfButton
      disableOnRequest
      isLoadingBtn
      (click)="acceptInvitation()"
      [loading]="(loading.loadingState$ | async) ?? false"
    >
      <cf-button-loading [loadingText]="'loading' | translate">
        {{ "admin.accept_invitation" | translate }}
      </cf-button-loading>
    </button>
  </div>
</div>
