<div class="tw:flex tw:w-full tw:items-center tw:gap-2 tw:justify-between">
  <div
    class="tw:flex tw:items-center tw:min-w-0 tw:gap-2 tw:max-w-full tw:cursor-pointer"
    (click)="toggleDropdown($event)"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  >
    @if (invitations().length) {
      <span
        class="tw:w-2 tw:h-2 tw:bg-peach-50 tw:rounded-full tw:absolute tw:-top-1 tw:-left-1 tw:z-10 tw:animate-ping"
      ></span>
      <span class="tw:w-2 tw:h-2 tw:bg-peach-50 tw:rounded-full tw:absolute tw:-top-1 tw:-left-1 tw:z-10"></span>
    }
    @if (selectedOrganization(); as organization) {
      <cf-organization-avatar
        class="tw:shring-0 tw:block"
        [logo]="organization.logo"
        [name]="organization.name"
        [size]="actualAvatarSize()"
      />
      <div
        class="tw:truncate tw:min-w-0 tw:text-gray-80 tw:text-sm tw:font-semibold"
        [matTooltip]="organization.name"
        [matTooltipDisabled]="nameWrapper.scrollWidth <= nameWrapper.clientWidth"
        #nameWrapper
      >
        {{ organization.name }}
      </div>
    } @else {
      <div>{{ "organization-picker.loading" | translate }}</div>
    }
    @if (!compact() && hasChevron()) {
      <svg
        cfIcon="chevron_down"
        class="tw:text-gray-80 tw:transition-transform tw:duration-300"
        [class.tw:rotate-180]="isDropdownOpen()"
      ></svg>
    }
  </div>
  @if (hasAdminLink() && !compact() && isSelectedOrganizationManager()) {
    <svg
      cfIcon="adjust_horizontal_alt"
      class="tw:cursor-pointer tw:text-gray-70"
      routerLink="/admin"
      cfOrgRouterLink
      [tp]="'admin.settings' | translate"
    ></svg>
  }
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isDropdownOpen()"
  [cdkConnectedOverlayWidth]="dropdownWidth"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayPush]="false"
  [cdkConnectedOverlayOffsetY]="8"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayDisposeOnNavigation]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'tw:bg-transparent'"
  (detach)="closeDropdown()"
  (backdropClick)="closeDropdown()"
>
  <div
    class="tw:w-full tw:bg-white tw:rounded-lg tw:max-w-full tw:border tw:border-gray-10 tw:overflow-auto tw:mb-6"
    @scaleFade
  >
    <div class="tw:flex tw:flex-col">
      @for (organization of organizations(); track organization.id) {
        <div
          class="tw:flex tw:items-center tw:gap-2 tw:justify-between tw:py-2 tw:px-3 tw:bg-white tw:hover:bg-blue-5 tw:w-full tw:cursor-pointer tw:[&.active]:bg-blue-5"
          [class.active]="organization.selected"
          (click)="selectOrganization($event, organization)"
        >
          <div class="tw:flex tw:items-center tw:gap-2 tw:min-w-0">
            <cf-organization-avatar [logo]="organization.logo" [name]="organization.name" size="xs" />
            <div
              class="tw:truncate tw:min-w-0 tw:text-gray-80 tw:text-sm tw:font-semibold"
              [matTooltip]="organization.name"
              matTooltipPosition="right"
              [matTooltipDisabled]="nameWrapper.scrollWidth <= nameWrapper.clientWidth"
              #nameWrapper
            >
              {{ organization.name }}
            </div>
          </div>
          @if (organization.selected) {
            <div>
              <svg cfIcon="check_mark" class="tw:text-green-70"></svg>
            </div>
          }
        </div>
      }
      <a
        class="tw:flex tw:items-center tw:gap-2 tw:justify-between tw:py-2 tw:px-3 tw:bg-white tw:hover:bg-blue-5 tw:w-full tw:cursor-pointer tw:[&.active]:bg-blue-5 tw:text-blue-50 tw:text-xs tw:font-medium tw:no-underline"
        routerLink="/admin/organizations"
        cfOrgRouterLink
      >
        <span>
          {{ "admin.go_to_organizations_link" | translate }}
          <svg cfIcon="arrow_right" size="sm" class="tw:ml-1 tw:text-blue-50 tw:inline-block"></svg>
        </span>
      </a>
    </div>
    @if (invitations(); as invitations) {
      @for (item of invitations; track $index) {
        <div class="tw:h-px tw:bg-gray-10 tw:mx-2"></div>
        <div class="tw:flex tw:flex-col">
          <div
            class="tw:flex tw:gap-2 tw:justify-between tw:py-2 tw:px-3 tw:bg-white tw:w-full tw:[&.active]:bg-blue-5 tw:items-start"
          >
            <div class="tw:relative">
              <span
                class="tw:w-2 tw:h-2 tw:bg-peach-50 tw:rounded-full tw:absolute tw:-top-1 tw:-left-1 tw:z-10 tw:animate-ping"
              ></span>
              <span
                class="tw:w-2 tw:h-2 tw:bg-peach-50 tw:rounded-full tw:absolute tw:-top-1 tw:-left-1 tw:z-10"
              ></span>
              <cf-organization-avatar [logo]="item.organization.logo" [name]="item.organization.name" size="xs" />
            </div>
            <div
              [innerHTML]="
                'admin.invitation'
                  | translate: { invitor: item.invitor_name, organization_name: item.organization.name }
              "
            ></div>
          </div>
          <a
            href=""
            (click)="manageInvitation($event, item)"
            class="tw:flex tw:items-center tw:gap-2 tw:justify-between tw:py-2 tw:px-3 tw:bg-white tw:hover:bg-blue-5 tw:w-full tw:cursor-pointer tw:[&.active]:bg-blue-5 tw:text-blue-50 tw:text-xs tw:font-medium tw:no-underline"
          >
            <span>
              {{ "admin.review_invitation" | translate }}
              <svg cfIcon="arrow_right" size="sm" class="tw:ml-1 tw:text-blue-50 tw:inline-block"></svg>
            </span>
          </a>
        </div>
      }
    }
  </div>
</ng-template>
