import { inject, Injectable } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { AccountService, FirstDayOfWeekEnum } from '@cf/temp/services';

@Injectable()
export class CustomLuxonDateAdapter extends LuxonDateAdapter {
  accountService = inject(AccountService);
  override getFirstDayOfWeek(): number {
    if (this.accountService.account.first_day_of_week === FirstDayOfWeekEnum.Sunday) {
      return 0;
    }
    if (this.accountService.account.first_day_of_week === FirstDayOfWeekEnum.Monday) {
      return 1;
    }
    return 6;
  }
}
