import { Component, computed, EventEmitter, inject, Input, OnChanges, Output } from '@angular/core';
import { NgxTolgeeModule, TranslateService } from '@tolgee/ngx';
import { Observable, Subscription } from 'rxjs';

import { AsyncPipe, NgIf } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { CookieService, LayoutService, NavigationService } from '@cf/core';
import { LanguagePickerComponent } from '@cf/features/i18n';
import { OrgRouterLinkDirective } from '@cf/features/organizations/admin';
import { DropdownComponent, DropdownItemComponent } from '@cf/shared/dropdown';
import { BackButtonComponent } from '@cf/shared/ui/button';
import { AvatarComponent, OrganizationAvatarComponent } from '@cf/shared/ui/logos';
import {
  AccountService,
  IUserNotice,
  OrganizationsService,
  OrganizationsStore,
  UsersService,
} from '@cf/temp/services';
import { GoogleOneTapComponent } from '../google-one-tap/google-one-tap.component';
import { UserNoticeComponent } from '../user-notice/user-notice.component';

@Component({
  selector: '[cf-header-user-flow]',
  templateUrl: './header-user-flow.component.html',
  styleUrls: ['./header-user-flow.component.scss'],
  providers: [],
  imports: [
    NgIf,
    UserNoticeComponent,
    BackButtonComponent,
    OrganizationAvatarComponent,
    DropdownComponent,
    AvatarComponent,
    DropdownItemComponent,
    RouterLink,
    GoogleOneTapComponent,
    AsyncPipe,
    NgxTolgeeModule,
    LanguagePickerComponent,
    OrgRouterLinkDirective,
  ],
})
export class HeaderUserFlowComponent implements OnChanges {
  #organizationsStore = inject(OrganizationsStore);
  #navigationService = inject(NavigationService);
  #cookieService = inject(CookieService);
  public readonly accountService = inject(AccountService);
  public readonly translateService = inject(TranslateService);
  public readonly layoutService = inject(LayoutService);
  private readonly usersService = inject(UsersService);
  public organizationsService = inject(OrganizationsService);
  public router = inject(Router);

  @Input() isAuthorized: boolean | null = false;

  @Output() register: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() login: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() logout: EventEmitter<unknown> = new EventEmitter<unknown>();

  notices$!: Observable<IUserNotice[]>;

  noticeSub$!: Subscription;

  hasBack = computed(() => this.layoutService.hasBack());

  backUrl = computed(() => this.#navigationService.backUrl() || this.hasBack());

  selectedOrganization = computed(() => {
    const actAsOrganization = this.#organizationsStore.actAsOrganization();
    if (actAsOrganization) {
      const organization = this.#organizationsStore.entityMap()[actAsOrganization];
      if (organization) {
        return organization;
      }
    }
    return this.#organizationsStore.selectedEntity();
  });

  ngOnChanges() {
    if (this.isAuthorized) {
      this.notices$ = this.usersService.notices();
    }
  }

  dismissNotice(id: number) {
    this.noticeSub$?.unsubscribe();
    this.noticeSub$ = this.usersService.noticeDismiss(id).subscribe(() => {
      this.notices$ = this.usersService.notices();
    });
  }
}
