<header class="d-xl-none">
  <svg
    (click)="toggleSidenav.emit()"
    cfIcon="menu"
    size="lg"
    class="tw:text-raven-40 tw:cursor-pointer"
    *ngIf="authService.isAuthorized$ | async"
  ></svg>
  <!-- <div cf-main-logo routerLink="/dashboard" size="sm" logoHeight="32" class="ms-3"></div> -->
  <cf-organization-picker
    class="tw:ml-4 tw:min-w-0 tw:flex-[1_1_100%]"
    size="lg"
    [hasAdminLink]="false"
    [hasChevron]="false"
  />
  @if (!hideCreateButton()) {
    <cf-create-button *ngIf="authService.isAuthorized()" [compact]="true" class="tw:ml-auto"></cf-create-button>
  }
</header>

<div class="alert" *ngIf="(showCalendarAlert$ | async) && showCalendarAlert">
  <span></span>
  <div class="d-flex flex-column flex-xl-row" *ngIf="accountService.account as account">
    <cf-text size="sm" weight="500" *ngIf="!account.has_any_connected_calendar">
      {{ "calendars.not_connected_alert" | translate }}
    </cf-text>
    <cf-text size="sm" weight="500" *ngIf="account.has_any_unusable_calendar">
      {{ "calendars.inaccessible_alert" | translate: { email: account.email } }}
    </cf-text>

    <a cf-link size="sm" class="ms-0 ms-xl-1 mt-2 mt-xl-0" (click)="openWindow()">
      {{ "schedules.connect_calendar" | translate }}
    </a>
  </div>
  <!--  <cf-icon-->
  <!--    icon="close"-->
  <!--    class="pointer"-->
  <!--    color="grey-70"-->
  <!--    (click)="accountService.updateCalendarAlert(false)"-->
  <!--  ></cf-icon>-->
</div>
