import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LoadingService } from '@cf/core';
import {
  CfDialogButtonsDirective,
  CfDialogContentDirective,
  CfDialogDismissDirective,
  CfDialogTitleDirective,
  injectDialogData,
  injectDialogRef,
} from '@cf/shared/dialog';
import { ButtonDirective, ButtonLoadingComponent } from '@cf/shared/ui/button';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { OrganizationsService } from '@cf/temp/services';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { catchError, finalize, of, take } from 'rxjs';

interface ReviewInvitationData {
  invitationId: number;
  invitorName: string;
  organizationName: string;
  token?: string | null;
}

@Component({
  selector: 'cf-review-invitation',
  standalone: true,
  imports: [
    NgxTolgeeModule,
    CfDialogTitleDirective,
    CfDialogContentDirective,
    CfDialogDismissDirective,
    ButtonDirective,
    CfIconDirective,
    CfDialogButtonsDirective,
    ButtonLoadingComponent,
    AsyncPipe,
  ],
  templateUrl: './review-invitation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewInvitationModalComponent {
  loading = inject(LoadingService);
  ref = injectDialogRef<boolean>();
  data = injectDialogData<ReviewInvitationData>();

  #organizationsService = inject(OrganizationsService);

  acceptInvitation(): void {
    const request$ = this.data.token
      ? this.#organizationsService.acceptInvitationByToken(this.data.token)
      : this.#organizationsService.acceptInvitation(this.data.invitationId);

    request$
      .pipe(
        take(1),
        catchError(() => {
          this.ref.close(false);
          return of(false);
        }),
      )
      .subscribe((organisation) => {
        this.ref.close(organisation as never);
      });
  }

  declineInvitation(): void {
    const request$ = this.data.token
      ? this.#organizationsService.declineInvitationByToken(this.data.token)
      : this.#organizationsService.declineInvitation(this.data.invitationId);

    request$
      .pipe(
        take(1),
        finalize(() => this.ref.close(false)),
        catchError(() => {
          this.ref.close(false);
          return of(false);
        }),
      )
      .subscribe();
  }
}
