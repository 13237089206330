import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AccountQuery, AuthService } from '@cf/temp/services';

export const superAdminGuard: CanActivateFn = () => {
  const router = inject(Router);
  const accountQuery = inject(AccountQuery);
  const service = inject(AuthService);

  if (!service.isAuthorized()) {
    return router.parseUrl('/auth/login');
  }

  if (!accountQuery.getValue()?.is_superuser) {
    return router.parseUrl('/error/403');
  }

  return true;
};
