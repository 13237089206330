export interface ILang {
  label: string;
  value: LanguageEnum;
}

export enum LanguageEnum {
  English = 'en',
  Russian = 'ru-RU',
  German = 'de-DE',
  Spanish = 'es-ES',
  French = 'fr-FR',
  Portuguese = 'pt-BR',
}

export const LANGUAGES: ILang[] = [
  {
    label: 'EN',
    value: LanguageEnum.English,
  },
  {
    label: 'FR',
    value: LanguageEnum.French,
  },
  {
    label: 'RU',
    value: LanguageEnum.Russian,
  },
  {
    label: 'DE',
    value: LanguageEnum.German,
  },
  {
    label: 'ES',
    value: LanguageEnum.Spanish,
  },
  {
    label: 'PT',
    value: LanguageEnum.Portuguese,
  },
];

/**
 * Detects the browser language and matches it to available languages in LanguageEnum.
 * Returns English if no match is found.
 */
export function detectBrowserLanguage(): LanguageEnum {
  // Default to English
  let detectedLanguage = LanguageEnum.English;

  if (typeof window !== 'undefined' && window.navigator) {
    // Get browser language with fallbacks (navigator.language is the preferred language)
    const browserLang =
      window.navigator.language ||
      (window.navigator as any).userLanguage ||
      (window.navigator as any).browserLanguage ||
      'en';

    // Try to match exact language code first (e.g., 'fr-FR')
    const exactMatch = Object.values(LanguageEnum).find(
      (lang) => lang.toLowerCase() === browserLang.toLowerCase(),
    );

    if (exactMatch) {
      detectedLanguage = exactMatch as LanguageEnum;
    } else {
      // Try to match language base (e.g., 'fr' from 'fr-CA')
      const langBase = browserLang.split('-')[0].toLowerCase();

      // Find languages that start with the same base code
      const baseMatch = Object.values(LanguageEnum).find((lang) =>
        lang.toLowerCase().startsWith(langBase),
      );

      if (baseMatch) {
        detectedLanguage = baseMatch as LanguageEnum;
      }
    }
  }

  return detectedLanguage;
}
