import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { OrgRouterLinkDirective } from '@cf/features/organizations/admin';
import { PopoverComponent } from '@cf/shared/dropdown';
import { ButtonDirective } from '@cf/shared/ui/button';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { NgxTolgeeModule } from '@tolgee/ngx';

@Component({
  selector: 'cf-create-button',
  templateUrl: './create-button.component.html',
  styleUrls: ['./create-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PopoverComponent,
    RouterLink,
    NgxTolgeeModule,
    CfIconDirective,
    ButtonDirective,
    OrgRouterLinkDirective,
  ],
})
export class CreateButtonComponent {
  fullWidth = input<boolean>(false);
  compact = input<boolean>(false);
  emitNavigate = output();
}
