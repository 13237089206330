import { inject, Injectable, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  #router = inject(Router);
  #maxHistorySize = 50;
  #history = signal<string[]>([]);
  #currentIndex = signal<number>(-1);

  constructor() {
    // Subscribe to router events to automatically track navigation
    this.#router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.#addToHistory(event.urlAfterRedirects);
      });
  }

  /**
   * Navigate to a specific path and add it to history
   */
  navigate(path: string) {
    this.#router.navigate([path]);
  }

  /**
   * Add a path to the navigation history
   */
  #addToHistory(path: string) {
    // If we're not at the end of history, truncate forward history
    if (this.#currentIndex() < this.#history().length - 1) {
      const newHistory = this.#history().slice(0, this.#currentIndex() + 1);
      this.#history.set(newHistory);
    }

    // Special case: if navigating from /meeting/create to /meeting/edit, remove the create page
    const currentHistory = this.#history();
    const lastPath = currentHistory.length > 0 ? currentHistory[currentHistory.length - 1] : '';
    if (lastPath.includes('/meeting/create') && path.includes('/meeting/edit')) {
      const newHistory = [...currentHistory.slice(0, currentHistory.length - 1), path];
      this.#history.set(newHistory);
      this.#currentIndex.set(newHistory.length - 1);
      return;
    }

    // Don't add duplicate consecutive entries
    if (this.#history().length === 0 || this.#history()[this.#history().length - 1] !== path) {
      const newHistory = [...this.#history(), path];

      // Limit history size
      if (newHistory.length > this.#maxHistorySize) {
        newHistory.shift();
      }

      this.#history.set(newHistory);
      this.#currentIndex.set(newHistory.length - 1);
    }
  }

  /**
   * Navigate back in history
   * @returns true if navigation was successful, false if at beginning of history
   */
  back(): boolean {
    if (this.#currentIndex() > 0) {
      this.#currentIndex.update((index) => index - 1);
      const path = this.#history()[this.#currentIndex()];
      const parsed = this.#router.parseUrl(path);
      const queryParams = parsed.queryParams;
      const url = path.split('?')[0];
      this.#router.navigate([url], { queryParams });
      return true;
    }
    return false;
  }

  /**
   * Get the URL of the previous page
   */
  backUrl() {
    return this.#history()[this.#currentIndex() - 1];
  }

  /**
   * Navigate forward in history
   * @returns true if navigation was successful, false if at end of history
   */
  forward(): boolean {
    if (this.#currentIndex() < this.#history().length - 1) {
      this.#currentIndex.update((index) => index + 1);
      const path = this.#history()[this.#currentIndex()];
      this.#router.navigate([path], { skipLocationChange: true });
      return true;
    }
    return false;
  }

  /**
   * Get the current navigation history
   */
  getHistory() {
    return this.#history();
  }

  /**
   * Get the current position in history
   */
  getCurrentIndex() {
    return this.#currentIndex();
  }

  /**
   * Check if can navigate back
   */
  canGoBack(): boolean {
    return this.#currentIndex() > 0;
  }

  /**
   * Check if can navigate forward
   */
  canGoForward(): boolean {
    return this.#currentIndex() < this.#history().length - 1;
  }

  /**
   * Clear navigation history
   */
  clearHistory() {
    this.#history.set([]);
    this.#currentIndex.set(-1);
  }

  /**
   * Set the maximum history size
   */
  setMaxHistorySize(size: number) {
    this.#maxHistorySize = size;
    // Trim history if needed
    if (this.#history().length > size) {
      const newHistory = this.#history().slice(this.#history().length - size);
      this.#history.set(newHistory);
      this.#currentIndex.set(Math.min(this.#currentIndex(), newHistory.length - 1));
    }
  }
}
