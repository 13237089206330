import {
  HttpInterceptorFn,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { makeEnvironmentProviders } from '@angular/core';

import { BackendConfig } from './config/backend.config';

export function provideBackend(config: BackendConfig, interceptors: HttpInterceptorFn[]) {
  return makeEnvironmentProviders([
    provideHttpClient(withInterceptorsFromDi(), withInterceptors(interceptors)),
    { provide: BackendConfig, useValue: config },
  ]);
}
