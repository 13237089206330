<footer>
  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-between flex-column flex-lg-row">
      <div cf-main-logo routerLink="/dashboard" cfOrgRouterLink></div>

      <div class="my-3 my-lg-0 d-flex">
        <a target="_blank" href="https://calfrenzy.com/cookie-policy/"> {{ "footer.cookie_policy" | translate }} </a>
        <a target="_blank" href="https://calfrenzy.com/terms-of-use/"> {{ "footer.terms_of_service" | translate }} </a>
        <a target="_blank" href="https://calfrenzy.com/privacy-policy/"> {{ "footer.privacy_policy" | translate }} </a>
      </div>

      <div class="d-flex flex-wrap justify-content-center">
        <span> {{ "footer.copyright" | translate: { year: year.toString() } }} &nbsp;</span>
        <span> {{ "footer.all_rights_reserved" | translate }} </span>
      </div>
    </div>
  </div>
</footer>
