@use 'utils/breakpoints' as breakpoints;

cf-footer {
  display: block;
  margin-top: auto;

  footer {
    border-top: var(--base-border-blue-20);
    margin-top: 32px;
    padding: 1rem 1rem 0;
    font-weight: 400;

    a {
      text-decoration: underline;
      margin: 0 1rem;
      cursor: pointer;
      color: var(--base-grey-80);
      text-align: center;
      white-space: nowrap;
    }

    cf-text-truncate {
      a {
        display: none;
      }
    }

    @include breakpoints.lt-xl {
      padding-bottom: 1rem;
    }
  }
}
