import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Locale, LocaleOptions } from '@cf/core';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { AccountService, LanguageEnum } from '@cf/temp/services';
import { NgxTolgeeModule, TranslateService } from '@tolgee/ngx';
import { LangItemComponent } from '../lang-item/lang-item.component';

@Component({
  selector: 'cf-mobile-language-picker',
  imports: [CommonModule, LangItemComponent, CfIconDirective, NgxTolgeeModule],
  templateUrl: './mobile-language-picker.component.html',
})
export class MobileLanguagePickerComponent {
  // Injections
  translateService = inject(TranslateService);
  #accountService = inject(AccountService);

  // Signals
  selected = toSignal(this.translateService.languageAsync);
  opened = signal(true);

  // Computed Values
  currentLocale = computed(() => {
    return LocaleOptions.find((locale) => locale.abbr === this.selected())!;
  });

  localeDisplay = computed(() => {
    return this.currentLocale().abbr.split('-')[0].toUpperCase();
  });

  // Other Properties
  LocaleOptions = LocaleOptions;

  // Public Methods
  toggleOpened() {
    this.opened.update((prev) => !prev);
  }

  selectLanguage(locale: Locale) {
    this.translateService.changeLanguage(locale.abbr);
    this.#accountService
      .updatePartialAccount({
        language: locale.abbr as unknown as LanguageEnum,
      })
      .subscribe(() => {
        this.toggleOpened();
      });
  }
}
