import { Injectable, signal } from '@angular/core';
import { injectLocalStorage } from 'ngxtension/inject-local-storage';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  isPinned = injectLocalStorage<boolean>('sidenav-pinned', {
    defaultValue: true,
  });
  isOpened = signal(false);

  setPinned(value: boolean) {
    this.isPinned.set(value);
  }

  setOpened(value: boolean) {
    this.isOpened.set(value);
  }

  togglePinned() {
    this.isPinned.update((state) => !state);
  }

  toggleOpened() {
    this.isOpened.update((state) => !state);
  }
}
