/* eslint-disable no-untranslated-string/no-untranslated-string */
/// <reference types="@angular/localize" />

import {
  ErrorHandler,
  enableProdMode,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { persistState } from '@datorama/akita';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideLuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter } from '@angular/material/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Router, RouterOutlet, provideRouter, withInMemoryScrolling } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { initTippy, languageInterceptor, provideBackend, provideEnvironment } from '@cf/core';
import { AuthInterceptor, CredentialsInterceptor } from '@cf/features/auth/shell';
import { OrgDialogEffects } from '@cf/features/organizations/admin';
import { provideSprites } from '@cf/shared/ui/icons';
import { AccountTimezoneToken, Is12hFormat } from '@cf/shared/ui/typography';
import { AccountService, OrganizationsEffect, TimeFormatEnum } from '@cf/temp/services';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideEffects, provideEffectsManager } from '@ngneat/effects-ng';
import { FormatIcu } from '@tolgee/format-icu';
import { BackendFetch, NgxTolgeeModule, TOLGEE_INSTANCE, Tolgee } from '@tolgee/ngx';
import { DevTools } from '@tolgee/web';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';
import { RecaptchaModule } from 'ng-recaptcha';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxStripeModule } from 'ngx-stripe';
import { provideSvgSprites } from 'ngxtension/svg-sprite';
import { initAnalytics } from './analytics';
import { AppComponent } from './app/app.component';
import { AuthDialogEffect } from './app/libs/auth/lib/effects';
import { CustomLuxonDateAdapter } from './app/modules/core/services/custom-luxon-date-adapter';
import { DialogEffects } from './app/modules/routing/app-container/modals/effects/dialog.effects';
import { appRoutes } from './app/routes';

persistState();

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://d68285913518454faa1c6707ddb7ef4d@sentry.bitstudios.com/40',
    environment: environment.sentryEnv,
    release: environment.buildVersion,
    enabled: !location.hostname.includes('localhost'),
    integrations: [
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
      Sentry.browserApiErrorsIntegration({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        XMLHttpRequest: false,
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [500],
      }),
    ],
    tracePropagationTargets: [environment.envName, environment.sentryEnv, environment.apiUrl],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      console.log(event);
      console.log(hint);
      const error = hint.originalException as any;
      if (error && error.message && error.message.match(/NG0911/i)) {
        event.fingerprint = ['NG0911'];
      }
      return event;
    },
  });
  if (environment.envName === 'prod' && location.hostname != 'localhost') {
    initAnalytics();
  }
}
bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      NgbModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:30000',
      }),
      environment.production ? [] : AkitaNgDevtools.forRoot(),
      AkitaNgRouterStoreModule,
      NgxTolgeeModule,
      NgxStripeModule.forRoot(),
      RecaptchaModule,
      RouterOutlet,
    ),
    {
      provide: TOLGEE_INSTANCE,
      useFactory: () => {
        return Tolgee()
          .use(DevTools())
          .use(FormatIcu())
          .use(
            BackendFetch({
              prefix: '/assets/i18n/',
              fallbackOnFail: true,
            }),
          )
          .init({
            language: 'en',
            fallbackLanguage: 'en',

            // for development
            apiUrl: environment.tolgeeApiUrl,
            apiKey: environment.tolgeeApiKey,

            tagNewKeys: ['FE'],
          });
      },
    },
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
      }),
    ),
    provideLuxonDateAdapter(),
    {
      provide: DateAdapter,
      useClass: CustomLuxonDateAdapter,
    },
    provideBackend(
      {
        apiUrl: environment.apiUrl,
      },
      [languageInterceptor],
    ),
    ...initTippy(),
    provideZxvbnServiceForPSM(),
    provideSvgSprites({
      name: 'flags',
      baseUrl: 'assets/sprites/flags.svg?3',
      classes: () => ['sprite-flag'],
    }),
    provideSprites(),
    provideEnvironmentNgxMask(),
    provideEnvironment(environment),
    provideEffectsManager({
      dispatchByDefault: false,
    }),
    provideEffects(AuthDialogEffect, OrganizationsEffect, OrgDialogEffects),
    {
      provide: Is12hFormat,
      useFactory: () => {
        const accountService = inject(AccountService);
        return () => accountService.account?.time_format === TimeFormatEnum['12h'];
      },
    },
    {
      provide: AccountTimezoneToken,
      useFactory: () => {
        const accountService = inject(AccountService);
        return () => accountService.account.timezone;
      },
    },
    provideEffects(DialogEffects),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(Sentry.TraceService);
    }),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
