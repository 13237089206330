import { Component, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Locale, LocaleOptions } from '@cf/core';
import { DropdownComponent, DropdownItemComponent } from '@cf/shared/dropdown';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { TextComponent } from '@cf/shared/ui/typography';
import { AccountService, LanguageEnum } from '@cf/temp/services';
import { TranslateService } from '@tolgee/ngx';
import { FlagDirective } from '../../directives';
import { LangItemComponent } from '../lang-item/lang-item.component';

@Component({
  selector: 'cf-language-picker',
  imports: [
    DropdownComponent,
    DropdownItemComponent,
    TextComponent,
    FlagDirective,
    CfIconDirective,
    LangItemComponent,
  ],
  templateUrl: './language-picker.component.html',
  styleUrl: './language-picker.component.scss',
})
export class LanguagePickerComponent {
  // Injections
  translateService = inject(TranslateService);
  #accountService = inject(AccountService);

  // Signals
  selected = toSignal(this.translateService.languageAsync);

  // Computed Values
  currentLocale = computed(() => {
    return LocaleOptions.find((locale) => locale.abbr === this.selected())!;
  });

  localeDisplay = computed(() => {
    return this.currentLocale().abbr.split('-')[0].toUpperCase();
  });

  // Other Properties
  LocaleOptions = LocaleOptions;

  // Public Methods
  selectLanguage(locale: Locale) {
    this.translateService.changeLanguage(locale.abbr);
    this.#accountService
      .updatePartialAccount({
        language: locale.abbr as unknown as LanguageEnum,
      })
      .subscribe();
  }
}
