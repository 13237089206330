import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  input,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { QueryParamsHandling, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ResponsiveService } from '@cf/core';
import { MobileLanguagePickerComponent } from '@cf/features/i18n';
import {
  OrganizationPickerComponent,
  OrgRouterLinkDirective,
} from '@cf/features/organizations/admin';
import { ButtonDirective, LinkComponent } from '@cf/shared/ui/button';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { AvatarComponent, MainLogoComponent } from '@cf/shared/ui/logos';
import {
  AccountService,
  AuthService,
  manageInvitation,
  OrganizationInvitations,
  OrganizationsRolesEnum,
  OrganizationsService,
  OrganizationsStore,
} from '@cf/temp/services';
import { Actions } from '@ngneat/effects-ng';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { CreateButtonComponent } from '../create-button/create-button.component';
import { SidenavService } from './sidenav.service';

@Component({
  selector: 'cf-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  imports: [
    CommonModule,
    MainLogoComponent,
    AvatarComponent,
    RouterLink,
    LinkComponent,
    RouterLinkActive,
    NgxTolgeeModule,
    CreateButtonComponent,
    ReactiveFormsModule,
    CfIconDirective,
    OrganizationPickerComponent,
    ButtonDirective,
    OrgRouterLinkDirective,
    MobileLanguagePickerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class.pinned]': 'pinned() || opened()',
    '[class.opened]': 'opened() || !isTouch()',
    '[class.hovered]': 'hovered() && !isTouch()',
    '[class.touch]': 'isTouch()',
  },
})
export class SidenavComponent {
  #organizationsStore = inject(OrganizationsStore);
  #sidenavService = inject(SidenavService);

  readonly pinned = this.#sidenavService.isPinned;
  readonly opened = this.#sidenavService.isOpened;
  hovered = signal(false);
  #actions = inject(Actions);

  isAuthorized = input<boolean | null>(false);
  toggleSidenav() {
    this.#sidenavService.toggleOpened();
  }

  router = inject(Router);
  elementRef = inject(ElementRef);
  authService = inject(AuthService);
  accountService = inject(AccountService);
  responsiveService = inject(ResponsiveService);
  organizationsService = inject(OrganizationsService);

  isMobile!: boolean;

  isTouch = toSignal(this.responsiveService.isTouch$);
  hideCreateButton = computed(
    () => this.#organizationsStore.selectedEntity()?.role === OrganizationsRolesEnum.MEMBER,
  );

  private _mouseleave!: ReturnType<typeof setTimeout>;

  @HostListener('mouseenter') onMouseEnter() {
    clearTimeout(this._mouseleave);
    setTimeout(() => {
      this.hovered.set(true);
    }, 150);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this._mouseleave = setTimeout(() => {
      this.hovered.set(false);
    }, 150);
  }

  getQueryParamsHandling(route: string): QueryParamsHandling {
    if (this.router.url.split('?')[0] === route) {
      return 'preserve';
    }
    return '';
  }

  closeSidenav() {
    if (this.isMobile) {
      this.toggleSidenav();
    } else {
      if (!this.pinned()) {
        this.immediatelyClose();
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  immediatelyClose() {
    setTimeout(() => {
      this.unpinNavigation();
      this.hovered.set(false);
    }, 100);
  }

  pinNavigation() {
    this.#sidenavService.setPinned(true);
  }

  unpinNavigation() {
    this.#sidenavService.setPinned(false);
  }

  organisationChange(event: string | number | undefined): void {
    this.#organizationsStore.selectEntity(event as number);
    setTimeout(() => {
      location.reload();
    }, 100);
  }

  manageInvitation(event: Event, invitation: OrganizationInvitations): void {
    event.preventDefault();
    this.#actions.dispatch(manageInvitation({ invitation }));
  }
}
