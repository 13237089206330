import { SlicePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { FlagDirective } from '../../directives';

@Component({
  selector: 'cf-lang-item',
  imports: [FlagDirective, SlicePipe],
  templateUrl: './lang-item.component.html',
  host: {
    class: 'tw:flex tw:items-center tw:gap-2',
  },
})
export class LangItemComponent {
  flag = input.required<string>();
  abbr = input.required<string>();
}
