<ng-container *ngIf="isAuthorized; then authorized; else notAuthorized"></ng-container>

<ng-template #authorized>
  @for (notice of notices$ | async; track $index) {
    <cf-user-notice class="mb-3" [notice]="notice" (emitDismiss)="dismissNotice(notice.id)"></cf-user-notice>
  }

  <div class="wrapper" *ngIf="accountService.account$ | async as account">
    <div class="tw:flex tw:items-center tw:gap-4 tw:mr-6">
      <cf-back-button *ngIf="hasBack()" [link]="backUrl()"></cf-back-button>
      @if (layoutService.showOrganizationName()) {
        <div
          class="tw:m-0 tw:hidden tw:xl:flex tw:items-center tw:gap-2"
          *ngIf="selectedOrganization() as selectedOrganization"
        >
          <cf-organization-avatar [name]="selectedOrganization.name" [logo]="selectedOrganization.logo" />
          <h6 class="tw:m-0">{{ selectedOrganization.name }}</h6>
        </div>
      }
    </div>

    <div class="tw:items-center tw:ml-auto tw:hidden tw:xl:flex">
      @if (account.is_superuser) {
        <div class="lng-picker">
          <cf-language-picker />
        </div>
      }

      <cf-dropdown placement="bottom-right">
        <cf-avatar cf-dropdown-toggle alt="User Logo" [src]="account.profile_picture"></cf-avatar>
        <cf-dropdown-item routerLink="/settings/profile">
          {{ "header.my_profile" | translate }}
        </cf-dropdown-item>
        <cf-dropdown-item routerLink="/admin/organizations" cfOrgRouterLink>
          {{ "header.my_organizations" | translate }}
        </cf-dropdown-item>
        @if (account.is_superuser) {
          <cf-dropdown-item routerLink="/stats"> {{ "admin.stats" | translate }} </cf-dropdown-item>
        }

        <cf-dropdown-item (click)="logout.emit()">
          {{ "header.logout" | translate }}
        </cf-dropdown-item>
      </cf-dropdown>
    </div>
  </div>
</ng-template>

<ng-template #notAuthorized>
  <div class="ms-auto">
    <cf-google-one-tap></cf-google-one-tap>
  </div>
</ng-template>
