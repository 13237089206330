import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@cf/core';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { LinkComponent } from '../link/link.component';

@Component({
  selector: 'cf-back-button',
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LinkComponent, CfIconDirective, NgxTolgeeModule],
})
export class BackButtonComponent {
  #navigationService = inject(NavigationService);
  @Input() link!: string | null | boolean;

  constructor(
    private readonly location: Location,
    private readonly router: Router,
  ) {}

  public back() {
    console.log('back', this.link);
    if (typeof this.link === 'boolean') {
      this.location.back();
      return;
    }
    if (this.link) {
      const parsed = this.router.parseUrl(this.link);
      console.log('parsed', parsed);
      // void this.router.navigate(this.link.split('?')[0].split('/'), {
      // queryParams: parsed.queryParams,
      // });
      this.#navigationService.back();
      return;
    }
    this.location.back();
  }
}
