@use 'utils/breakpoints' as breakpoints;
@layer components {
  cf-sidenav {
    display: none;

    &.opened {
      display: block;
    }

    --sidebar-width: 230px;

    &.touch {
      --sidebar-width: 280px;
    }

    &.touch.opened .wrapper {
      animation: slide-in-left 0.2s ease-in-out;
    }

    min-width: 64px;
    width: 64px;

    transition: all 0.2s ease-in-out;

    &.pinned {
      width: var(--sidebar-width);
      min-width: var(--sidebar-width);

      @include breakpoints.lt-xl {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 90;
      }
    }

    &.hovered,
    &.pinned {
      .pin {
        display: block !important;
      }

      .wrapper {
        width: var(--sidebar-width) !important;

        padding: 18px;

        @include breakpoints.lt-xl {
          padding: 34px 24px;
        }

        section {
          align-items: flex-start;
        }

        .logo {
          &-sm {
            display: none;
          }

          &-lg {
            display: block;
          }
        }

        cf-create-button .cf-btn-create {
          padding: 8px 40px !important;
        }

        .menu-items {
          a[cf-link] {
            @include breakpoints.lt-xl {
              padding: 8px 0;
            }
          }
        }

        span.item {
          display: block !important;
          white-space: nowrap;
        }
      }
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: var(--base-black-50);
    }

    .pin {
      position: absolute;
      top: 10px;
      right: 10px;
      display: none !important;
      cursor: pointer;
    }

    .wrapper {
      background: var(--base-white);
      box-shadow: var(--sidenav-shadow);

      position: fixed;
      left: 0;
      top: 0;
      z-index: 9;
      width: 64px;
      height: 100dvh;
      overflow-y: auto;
      overflow-x: hidden;

      transition: all 0.2s ease-in-out;

      padding: 18px 12px;

      .line {
        margin: 16px 0;

        width: 100%;
        border-bottom: 2px solid var(--base-raven-5);
      }

      section {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
      }

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      .logo {
        &-sm {
          display: block;
        }

        &-lg {
          display: none;
        }
      }

      cf-create-button {
        cf-button {
          .cf-btn-create {
            padding: 8px;
          }

          span.item {
            display: none;
            margin-right: 8px;
          }
        }
      }

      .menu-items {
        display: flex;
        flex-direction: column;
        width: 100%;

        a[cf-link] {
          padding: 8px 0 8px 8px;

          &:hover {
            background-color: var(--base-raven-5);
          }

          &.active {
            .cf-link {
              color: var(--base-blue-50) !important;

              mat-icon {
                color: var(--base-blue-50) !important;
              }
            }
          }

          .cf-link {
            display: flex;
            align-items: center;

            span.item {
              display: none;
            }
          }
        }

        hr {
          border-width: 2px;
          border-color: var(--base-raven-10);
          margin: 24px 20px;
        }
      }

      .settings {
        position: absolute;
        right: 22px;
        z-index: 9999;
      }
    }
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
