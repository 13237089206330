import { Injectable, NgZone, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { TranslateService } from '@tolgee/ngx';

@Injectable({
  providedIn: 'root',
})
export class OpenReplayService {
  translateService = inject(TranslateService);
  public tracker?: Tracker | null;

  confirmText = toSignal(this.translateService.translate('open-reply.confirmText'));

  constructor(private zone: NgZone) {
    this.zone.runOutsideAngular(() => {
      this.tracker = new Tracker({
        // eslint-disable-next-line no-untranslated-string/no-untranslated-string
        projectKey: 'INST95QW7UeJLGZE2tiZ',
      });

      this.tracker.use(
        trackerAssist({
          confirmText: this.confirmText(),
        }),
      );
    });
  }

  public async start() {
    this.zone.runOutsideAngular(() => {
      if (this.tracker) {
        return this.tracker.start();
      } else {
        return {
          sessionID: null,
          sessionToken: null,
          userUUID: null,
        };
      }
    });
  }

  public setUserData(user: { id: string }): void {
    this.zone.runOutsideAngular(() => {
      if (this.tracker && user.id) {
        this.tracker.setUserID(String(user.id));
      }
    });
  }
}
