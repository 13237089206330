import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'cf-pull-to-refresh',
  imports: [],
  templateUrl: './pull-to-refresh.component.html',
  styleUrl: './pull-to-refresh.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PullToRefreshComponent implements AfterViewInit {
  @ViewChild('pullToRefresh') pullToRefresh!: ElementRef<HTMLDivElement>;

  ngAfterViewInit() {
    this.handleTouchReload();
  }

  handleTouchReload() {
    const element = this.pullToRefresh.nativeElement;
    let touchstartY = 0;

    fromEvent<TouchEvent>(document, 'touchstart').subscribe((e: TouchEvent) => {
      touchstartY = e.touches[0].clientY;
    });

    fromEvent<TouchEvent>(document, 'touchmove').subscribe((e: TouchEvent) => {
      const touchY = e.touches[0].clientY;
      const touchDiff = touchY - touchstartY;
      if (touchDiff > 50 && window.scrollY === 0) {
        element?.classList.add('visible');
      }
    });
    fromEvent(document, 'touchend').subscribe(() => {
      if (element?.classList.contains('visible')) {
        element.classList.remove('visible');
        location.reload();
      }
    });
  }
}
