import { CanActivateFn } from '@angular/router';
import { injectEnvironment } from '@cf/core';

export const legacyURLredirectionGuard: CanActivateFn = (route, state) => {
  // Inject environment once and directly construct the URL
  const bookingUrl = injectEnvironment().bookingUrl?.replace(/\/$/, '');
  const redirectPath = state.url.replace('/booking/', '').replace(/^\//, '');
  const url = bookingUrl + '/' + redirectPath;

  if (url.includes('reschedule') && !!route.queryParams['rescheduleEvent']) {
    return true;
  }

  // Redirect to the constructed URL
  window.open(url, '_self');

  // Always return false to prevent route activation after redirection
  return false;
};
